export default ({ asyncCallsLoaded, brandId, maxCarYear}) => {
  return [
    {
      id: 'labelVehicleData',
      tag: 'label',
      content: 'Dados do Veículo'
    },
    {
      id: 'plate',
      model: 'plate',
      tag: 'InputGroup',
      rules: ['required', 'carPlate'],
      props: {
        label: 'Placa',
        mask: 'carPlate',
        invalidLabel: 'Insira uma placa de carro',
      }
    },
    {
      id: 'brand_id',
      model: 'brand_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Fabricante',
        label: 'Fabricante',
        placeholder: 'Selecione',
        invalidLabel: 'Selecione um fabricante',
        selectOptions: null,
        fetchOptions: 'drivers/fetchVehicleBrands',
      },
      events: {
        ...(asyncCallsLoaded ? {input: 'vehicleBrandChange'} : '')
      }
    },
    {
      id: 'model_id',
      model: 'model_id',
      tag: 'BaseTreeSelect',
      refs: 'vehicleModel',
      rules: ['required'],
      props: {
        name: 'Modelo',
        label: 'Modelo',
        searchLabel: 'Modelo',
        placeholder: 'Selecione',
        invalidLabel: 'Insira um modelo',
        autoLoadRootOptions: false,
        selectOptions: null,
        fetchOptions: 'drivers/fetchVehicleModels',
        fetchParams: brandId,
        readOnly: !brandId
      }
    },
    {
      id: 'year',
      model: 'year',
      tag: 'BaseDatePicker',
      rules: ['required'],
      props: {
        label: 'Ano',
        format: 'YYYY',
        notAfter: maxCarYear,
        type: 'year',
        invalidLabel: 'Insira um ano',
        range: false,
        prependIcon: false,
        placeholder: '',
        clearable: false,
      }
    },
    {
      id: 'antt',
      model: 'antt',
      tag: 'InputGroup',
      props: {
        label: 'Registro ANTT',
        invalidLabel: 'Insira um registro ANTT',
      }
    },
    {
      id: 'renavam',
      model: 'renavam',
      tag: 'InputGroup',
      rules: ['required', 'renavam'],
      props: {
        label: 'RENAVAM',
        mask: 'number',
        invalidLabel: 'Insira um RENAVAM',
      }
    },

    // {
    //   id: 'labelVehicle',
    //   tag: 'label',
    //   content: 'Veículo'
    // },
    // {
    //   id: 'vehicle_left_image',
    //   tag: 'ThumbAction',
    //   model: {
    //     document_images: 'vehicle_left_image'
    //   },
    //   props: {
    //     label: 'Lateral Esquerda',
    //     options: [],
    //     isApprovable: false
    //   }
    // },
    // {
    //   id: 'vehicle_right_image',
    //   tag: 'ThumbAction',
    //   model: {
    //     document_images: 'vehicle_right_image'
    //   },
    //   props: {
    //     label: 'Lateral Direita',
    //     options: [],
    //     isApprovable: false
    //   }
    // },
    // {
    //   id: 'vehicle_front_image',
    //   tag: 'ThumbAction',
    //   model: {
    //     document_images: 'vehicle_front_image'
    //   },
    //   props: {
    //     label: 'Frente',
    //     options: [],
    //     isApprovable: false
    //   }
    // },
    // {
    //   id: 'vehicle_back_image',
    //   tag: 'ThumbAction',
    //   model: {
    //     document_images: 'vehicle_back_image'
    //   },
    //   props: {
    //     label: 'Traseira',
    //     options: [],
    //     isApprovable: false
    //   }
    // },

    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Salvar',
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        filled: true
      }
    }
  ]
}