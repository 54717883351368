<template>
  <main class="VehiclesForm">
    <TheSubHeader 
      :icon="TheSubHeaderIcon" 
      :title="TheSubHeaderTitle"
      page-title-tag="Vehicles Form"
      :actions="['back']"
    />

    <div class="VehiclesForm__content">
      <DynamicForm
        ref="form"
        class="VehiclesForm__form"
        :model="form"
        :blueprint="blueprint({asyncCallsLoaded, brandId, maxCarYear})"
        :endpoint-errors="endpointErrors"
        @showLightbox="showLightbox"
        @vehicleBrandChange="vehicleBrandChange"
        @submit="onSubmit"
      />
    </div>

    <Modal v-if="!!lightbox" @close="showLightbox">
      <template #header>
        <div class="VehiclesForm__modalHeader">
          {{ modalTitle }}
        </div>
      </template>
      <template #body>
        <div class="VehiclesForm__modal">
          <img :alt="modalTitle" :src="lightbox">
        </div>
      </template>
    </Modal>
  </main>
</template>

<script>
import { vehicles } from '@/api'
import { mapState, mapActions } from 'vuex'
import { DynamicForm, Modal } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import blueprint from './VehiclesForm.blueprint'

export default {
  name: 'VehiclesForm',
  components: {
    TheSubHeader,
    DynamicForm,
    Modal,
  },
  data() {
    return {
      modalTitle: null,
      lightbox: '',
      form: {
        plate: '',
        brand_id: null,
        model_id: null,
        year: null,
        antt: '',
        renavam: '',
        status_id: {}
      },
      endpointErrors: {},

      //Helpers
      asyncCallsLoaded: false
    }
  },
  computed: {
    ...mapState({
      driversObservations: state => state.drivers.driversObservations
    }),
    
    vehicleId() {
      return this.$route.params.id
    },

    TheSubHeaderTitle() {
      return this.vehicleId ? 'Cadastro do Veículo' : 'Cadastrar Veículo'
    },

    TheSubHeaderIcon() {
      return this.vehicleId ? 'truck' : 'add-truck'
    },

    brandId() {
      return this.form.brand_id ? this.form.brand_id.id : ''
    },
    
    maxCarYear() {
      let today = new Date()
      let year = today.getFullYear() + 1
      return today.setFullYear(year)
    },

    getCurrentUser() {
      let user = JSON.parse(localStorage.getItem('user'))
      return user
    }

  },
  mounted() {
    this.initFetchVehicle()
  },
  methods: {
    blueprint,
    ...mapActions({
      setLoading: 'loading/setLoading',
      // fetchVehicleObservations: 'drivers/fetchDriversObservations'
    }),

    async initFetchVehicle() {
      // if (this.vehicleId) this.fetchVehicleObservations()
      if (this.vehicleId) await this.fetchVehicle()
      this.asyncCallsLoaded = true
    },

    async readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },

    async fetchVehicle() {
      this.setLoading(true)
      await vehicles.fetchVehicle({ id: this.vehicleId }, (res) => {
        for (let i in this.form) {
          if (res[i]) this.form[i] = res[i]
        }
      }, (e) => e)
      this.setLoading(false)
    },

    async showLightbox(e, label) {
      this.modalTitle = label
      this.lightbox = e instanceof File ? await this.readFile(e) : e
    },

    async createVehicle(body) {
      this.setLoading(true)
      body.set('owner_id', this.getCurrentUser.id)
      await vehicles.createVehicle(body, () => this.$router.push('/vehicles'), (e) => {this.endpointErrors = e.validation}, () => this.setLoading(false))
    },

    async updateVehicle(body) {
      this.setLoading(true)
      await vehicles.updateVehicle({ id: this.vehicleId, body }, () => this.$router.push('/vehicles'), (e) => {this.endpointErrors = e.validation}, () => this.setLoading(false))
    },

    async onSubmit(formData) {
      this.vehicleId ? this.updateVehicle(formData) : this.createVehicle(formData)
    },

    vehicleBrandChange() {
      this.form.model_id = null
      this.$refs.form.$refs.model_id[0].copyOption(null)
    },

    
  }
}
</script>

<style lang="scss" scoped>
$columnsSize: 160px 1fr;
@mixin fontDisplay($color, $weight: $font-weight-bold){
  font-weight: $weight;
  font-size: $font-size-1xmini;
  color: $color;
}

.VehiclesForm {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
    margin-bottom: 40px;
  }

  &__form {
    grid-template-areas:
      "labelVehicleData labelVehicleData . . . . . . . . . ."
      "plate plate brand_id brand_id model_id model_id model_id year antt antt renavam renavam"
      // "labelVehicle labelVehicle . . . . . . . . . ."
      // "vehicle_left_image vehicle_left_image vehicle_right_image vehicle_right_image vehicle_front_image vehicle_front_image vehicle_back_image vehicle_back_image . . . ."
      ". . . . . . . . . . . ."
      ". . . . . . . . . . confirm confirm";
  }
  &__modalHeader{
    display: flex;
    align-items: center;
    color: $color-neutral-stronger;
    font-weight: $font-weight-bold;
    font-size: $font-size-1xsmall;
    margin-left: 1%;
    height: 100%;
  }
  &__modal{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__Dialog{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $z-modal-bg;
    &--hide{
      display: none;
    }
  }
}
</style>